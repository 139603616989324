<template>
  <svg
    width="146"
    height="32"
    viewBox="0 0 334 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="shiftkey-logo"
      d="M14.9889 53.8098H34.5823V40.9654H14.9889C13.5099 40.9654 12.7703 39.2395 13.8173 38.228L34.5823 18.1667V0L4.41118 29.1486C-5.01157 38.2521 1.6608 53.8178 14.9889 53.8178V53.8098Z"
      fill="white"
    />
    <path
      d="M54.1661 31.0348C55.6452 31.0348 56.3847 32.7607 55.3377 33.7722L34.5728 53.8335V72.0002L64.7355 42.8596C74.1583 33.7562 67.4859 18.1904 54.1578 18.1904H34.5645V31.0348H54.1578H54.1661Z"
      fill="#0ABC28"
    />
    <path
      d="M115.468 30.9321C114.821 24.8725 108.898 20.6113 99.4843 20.6113C90.0712 20.6113 84.4314 24.8286 84.4314 30.1795C84.4314 34.9636 87.8861 38.3012 96.2269 39.4349L102.615 40.251C106.46 40.8179 107.957 41.6975 107.957 43.6522C107.957 45.7926 105.286 47.6202 100.071 47.6202C95.2456 47.6202 92.0084 45.6362 91.4672 42.2057H83.1719C83.8598 49.0618 90.1116 53.538 100.005 53.538C109.899 53.538 116.1 49.3208 116.1 43.1489C116.1 38.0471 111.993 34.9636 104.694 33.9569L98.0478 33.1409C93.4854 32.5105 92.5092 31.6309 92.5092 29.9303C92.5092 27.7264 95.2456 26.5291 99.2871 26.5291C103.865 26.5291 106.5 28.1857 107.137 30.9321H115.468Z"
      fill="white"
    />
    <path
      d="M140.992 20.6897C134.603 20.6897 130.633 23.9638 129.196 26.6075C129.459 25.161 129.459 23.8367 129.459 22.9571V10.6182H121.25V52.4191H129.459V36.2392C129.459 30.8247 133.046 27.0473 138.387 27.0473C143.728 27.0473 146.207 30.1308 146.207 35.9265V52.4191H154.416V34.4751C154.416 25.5373 149.009 20.6897 140.992 20.6897Z"
      fill="white"
    />
    <path d="M169.618 21.6953H161.408V52.4181H169.618V21.6953Z" fill="white" />
    <path
      d="M165.633 8.90332H165.395C162.526 8.90332 160.2 11.1492 160.2 13.9195C160.2 16.6899 162.526 18.9357 165.395 18.9357H165.633C168.502 18.9357 170.827 16.6899 170.827 13.9195C170.827 11.1492 168.502 8.90332 165.633 8.90332Z"
      fill="white"
    />
    <path
      d="M259.284 21.6954H247.878L237.777 30.8237C236.538 31.8304 235.172 33.0277 233.998 34.4106V10.6123H225.789V52.4132H233.998V43.0943L237.777 39.9472L249.638 52.4132H261.105L243.639 34.909L259.279 21.6905L259.284 21.6954Z"
      fill="white"
    />
    <path
      d="M278.172 20.6113C267.029 20.6113 259.472 27.4087 259.472 36.9818C259.472 47.0533 266.184 53.538 278.369 53.538C289.122 53.538 294.985 48.1871 295.703 42.2057H287.099C286.512 45.1671 283.579 47.4296 278.369 47.4296C271.722 47.4296 268.136 43.7157 267.878 38.9951H296.093V37.4852C296.093 27.4136 289.512 20.6113 278.172 20.6113ZM268.071 33.3266C268.789 29.3585 272.436 26.4656 278.172 26.4656C283.907 26.4656 287.033 29.2999 287.362 33.3266H268.076H268.071Z"
      fill="white"
    />
    <path
      d="M324.325 21.6953L317.158 39.0725C316.505 40.646 315.787 42.283 315.074 44.2328C314.355 42.2195 313.642 40.646 312.858 39.0725L305.301 21.6953H295.984L310.714 51.475L304.199 65.2604H313.126L333.263 21.6953H324.335H324.325Z"
      fill="white"
    />
    <path
      d="M203.269 27.8671V39.6343C203.269 48.9533 206.749 52.4228 216.117 52.4228H220.927V45.7427H217.275C212.338 45.7427 211.408 44.6529 211.408 38.8915V37.9435V27.872H221.529V21.7H211.362V10.6219H203.345V21.7H188.717V20.752C188.717 14.9906 189.647 13.9008 194.584 13.9008H198.236V7.2207H193.426C184.058 7.2207 180.578 10.6854 180.578 20.0092V21.7049H173.305V27.8769H180.604V52.4277H188.686V27.8769H203.269V27.8671Z"
      fill="white"
    />
  </svg>
</template>
